import Link from "next/link";

import { useEffect, useState } from "react";
import { BaseApiService } from "@/utils/BaseApiService";

const CategoryThree = () => {
  const [categories, setCategories] = useState([]);

  const fetchCourseCategories = async () => {
    let searchParameters = { searchTerm: '', offset: 0, limit: 0, commaSeparatedTypes: 'COURSE', isFeatured: true };
    new BaseApiService("/categories")
      .getRequestWithJsonResponse(searchParameters)
      .then(async (response) => {
        console.log("Featured Course Categories Response " + JSON.stringify(response));
        setCategories(response.records);
      })
      .catch((error) => {
        console.log("fetchCourseCategories:fetchDataFromServer>>>>" + error);
      });
  };

  useEffect(() => {
    fetchCourseCategories();
  }, []);


  return (
    <>
      {categories.slice(0, 8).map((item, innerIndex) => {
        const count = item.coursesCount;
        return (
          <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={innerIndex}>
            <div className="rbt-cat-box rbt-cat-box-1 variation-2 text-center">
              <div className="inner">
                <div className="thumbnail">
                  <Link href={`/courses?category=${item.id}`}>
                    <img src={item.imageUrl != null ? item.imageUrl : 'https://res.cloudinary.com/revival-gateway/image/upload/v1687342256/courses/lessons/297e018288dd4b820188dd6c19400005.jpg'} width={274} height={150} style={{widows: 274, height: 150}} alt="Category Images" />
                  </Link>
                </div>

                <div className="icons">
                  <img src={item.iconUrl != null ? item.iconUrl : 'https://res.cloudinary.com/revival-gateway/image/upload/v1687342256/courses/lessons/297e018288dd4b820188dd6c19400005.jpg'}  width={40} height={40} style={{width: 40, height: 40}} alt="Icons Images" />
                </div>
                <div className="content">
                  <h5 className="title">
                    <Link href={`/courses?category=${item.id}`}>
                      {item.name}
                    </Link>
                  </h5>
                  <div className="read-more-btn">
                    <Link
                      className="rbt-btn-link"
                      href={`/courses?category=${item.id}`}
                    >
                      {count} Course{count <= 1 ? "s" : ""}
                      <i className="feather-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CategoryThree;