import React, { useEffect } from "react";
// import "plyr/dist/plyr.css";

import dynamic from 'next/dynamic'
import Link from "next/link";
// import Plyr from "plyr";

import AboutData from "../../data/elements/about.json";

// const DynamicPlyr = dynamic(() => import('plyr-react').then(mod => mod.default), {
//   ssr: false,
// });

const AboutEight = () => {
  useEffect(() => {
    // const player = new Plyr(".rbtplayer", {
    //   muted: false,
    //   volume: 1,
    //   controls: [
    //     "play-large",
    //     "play",
    //     "progress",
    //     "current-time",
    //     "mute",
    //     "volume",
    //     "fullscreen",
    //   ],
    // });

    // return () => {
    //   if (player) {
    //     player.destroy();
    //   }
    // };
  }, []);

  return (
    <>
      {AboutData &&
        AboutData.aboutEight.map((data, index) => (
          <div className="row gy-5 align-items-center row--30" key={index}>
            <div className="col-lg-7">
              <div className="content radius-6 overflow-hidden">
                <div className="plyr__video-embed rbtplayer">
                  <iframe
                    className="radius-6 overflow-hidden"
                    src="https://www.youtube.com/embed/OOGhBO2tzk4"
                    allowFullScreen
                    allow="autoplay"
                    width={636}
                    height={414}
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="inner">
                <div className="section-title text-start">
                  <h3 className="title">{data.title}</h3>
                  <p className="description mt--20">
                    {data.desc}
                  </p>
                  <div className="read-more-btn mt--40">
                    <Link
                      className="rbt-btn btn-gradient rbt-switch-btn rbt-switch-y"
                      href="/pages/company-overview"
                    >
                      <span style={{paddingLeft: 15, paddingRight: 15}} data-text="View Our Company Overview">More About LearningKing</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default AboutEight;