import { BaseApiService } from "@/utils/BaseApiService";
import Link from "next/link";
import { useEffect, useState } from "react";


const CustomReview = () => {

  const [reviews, setReviews] = useState([]);

  const fetchCustomReviews = async () => {
    let searchParameters = { searchTerm: "", offset: 0, limit: 12, type: 'GENERAL' };
    try {
      const response = await new BaseApiService("/reviews").getRequestWithJsonResponse(
        searchParameters
      );
      // console.log("Barner Courses ", response);
      if (response && response.records) {
        setReviews(response.records);
        console.log("Custom Reviews: ", response.records); // Log the state update
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      console.error("fetchCustomReviews:fetchDataFromServer>>>>", error);
    }
  };

  useEffect(() => {
    fetchCustomReviews();
  }, []);

  console.log("Custom reviews ", reviews?.length);

  return (
    <>
      <div className="scroll-animation-wrapper" style={{ margin: 30, background: "#f7f7fe" }}>
        <div className="scroll-animation scroll-right-left">
          {reviews && reviews.slice(0, 4).map((data, index) => (
            <div className="single-column-20" key={index}>
              <div className="rbt-testimonial-box">
                <div className="inner">
                  <div className="clint-info-wrapper">
                    <div className="thumb">
                      <img
                        src={data.reviewerImageUrl}  // Use reviewerImageUrl for image source
                        width={494}
                        height={494}
                        alt="Reviewer Image"
                      />
                    </div>
                    <div className="client-info">
                      <h5 className="title">{data.reviewerName}</h5>
                      <span>
                        {data.reviewerDesignation}
                      </span>
                    </div>
                  </div>
                  <div className="description">
                    <p
                      className="subtitle-3"
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden'
                      }}
                    >{data.comment}</p>
                    <Link className="rbt-btn-link" href="#">
                      Read Project Case Study
                      <i className="feather-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="scroll-animation-wrapper" style={{ margin: 30, background: "#f7f7fe" }}>
        <div className="scroll-animation scroll-left-right">
          {reviews && reviews.slice(4, 8).map((data, index) => (
            <div className="single-column-20"  key={index}>
              <div className="rbt-testimonial-box">
                <div className="inner">
                  <div className="clint-info-wrapper">
                    <div className="thumb">
                      <img
                        src={data.reviewerImageUrl}  // Use reviewerImageUrl for image source
                        width={494}
                        height={494}
                        alt="Reviewer Image"
                      />
                    </div>
                    <div className="client-info">
                      <h5 className="title">{data.reviewerName}</h5>
                      <span>
                        {data.reviewerDesignation}
                      </span>
                    </div>
                  </div>
                  <div className="description">
                    <p
                      className="subtitle-3"
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden'
                      }}
                    >{data.comment}</p>
                    <Link className="rbt-btn-link" href="#">
                      Read Project Case Study
                      <i className="feather-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CustomReview;
